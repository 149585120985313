Card > img{
    width: 100px;
    height: 100px;
}

.bg-our-services-card{
    background: #C2F3FF;
    border: none;
    text-align: c;
}

.bg-our-services{
    background: #EEFAFD;
    
}
.bg-our-services-card{
    box-shadow: 2px 2px 4px rgb(236, 236, 236);
}
.bg-our-services-card:hover{
    box-shadow: 2px 2px 4px rgb(212, 211, 211);
}

.card{
    height: 255px !important;
}



@media only screen and (max-width: 576px) {
    .card{
        text-align: center;
    }
    .text-responsive-center{
        text-align: center;
    }
  }