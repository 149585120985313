.bg-provide{
    background: #EEFAFD;
}
.card-topper-image{
    position: relative;
}
.card-toppper{
    position: absolute;
    color: white;
    top: 0;
    width: 100%;
    text-align: left;
}
.card:hover .card-toppper{
    color: rgb(190, 181, 181);
    transition-duration: all 1s;
}
.card-topper-text{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #1a1919;
    opacity: 0.7;
    top: 230px;
    text-align: center;
    padding: 20px 0px;
}

.logo-icon-carousal{
    margin-top: 40px;
    margin-left: 15px;
    font-size: 40px;
    opacity: .6;
}

@media only screen and (max-width: 576px) {
    .card-topper-text{
        top: 245px;
    }
}