.review-bg{
    /* background-color: #0aabc7; */
    background-color: #f2fdff;
}
.iconstrategy-img img{
    height: 60px;
    width: 60px;
}
.card{
    border: none;
}
.review-card{
    height: 230px !important;
    box-shadow: 1px 1px 4px #e9d5d5;
}

.review-card:hover{
    box-shadow: 1px 1px 3px #a3a1a1;
    transition-duration: 1s;
}
@media only screen and (max-width: 576px){ 
    
    .iconstrategy-img{
        display: flex;
        align-items: center;
        justify-content: center;
    }

 }
