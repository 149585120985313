.text-responsive-center{
    float: right;
}

@media only screen and (max-width: 576px) {
    .text-responsive-center{
        float: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}