.logoRum{
    width: 161.33px;
    height: 40.53px;
    gap: 0px;
    opacity: 0px;
    
}
.logoStubhub{
    width: 105px;
    height: 48px;
    gap: 0px;
    opacity: 0px;

}
.logotrekntred{
    width: 90px;
    height: 45px;
    gap: 0px;
    opacity: 0px;
}
.logoes{
    /* padding: 35px 14px; */
    
}