.bg-our-brand{
    background-color: #dbf9ff;
}
.card{
    height: 330px;
    margin: 30px 0px;
}
.card-img-top{
    width: 100px;
    height: 100px;
}

.bg-card-peoply-text{
    background-color: #445585;
    color: #ffffff;
}

.peoply-bg{
    background: linear-gradient(180deg, #C2F3FF 0%, #749299 100%);
    width: 100%;
    text-align: center;
}