@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

p{
  font-weight: 400;
}

p, h1, h2, h3, h4, h5, h6{
  font-family: "Roboto", sans-serif;
  
}

.btn-design{
    width: 220px;
    height: 35px;
    padding: 5px 0px 5px 0px;
    border-radius: 23px;
    color: #fff;
    border: none;
    background: linear-gradient(91.56deg, #0078B7 3.36%, #40C1DF 87.58%, #48CAE4 97.52%);
}

.btn-design:hover{
  background: linear-gradient(91.56deg, #004f79 3.36%, #35cef0 87.58%, #4bdaf7 97.52%);
}

.space-button-icon{
  margin: 0px 10px;
}

@media only screen and (max-width: 576px) {
  .btn-design {
    width: 220px;
    height: 35px;
    margin: 0 auto;
    margin-top: -15px;
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}

.carousel-control-prev{
  width: 2% !important;
}

.carousel-control-next{
  width: 2% !important;
}