.bg-footer{
    background: #111C24;
}
.logo-bottom{
    height: 90px;
    width: 90px;
}
.footer-lower-news-text{
    color: #F8F9FA;
}
.btn-subscribe{
    width: 150px;
    border-radius: 5px;
    height: 38px;
}

#navbarScrollingDropdown{
    color: white;
}
@media only screen and (max-width: 576px) {
    .btn-subscribe{
        margin-top: 20px;
        display: flex;
    }
  }