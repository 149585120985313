.discover__bg{
    /* width: 1728px;
    height: 4844px; */
/* top: -1825px;
left: -578px;
gap: 0px;
opacity: 0px; */

    background: linear-gradient(173.05deg, #CAF0F8 0%, #FDFDFD 24.93%, #D7F8FF 49.85%, #FFFFFF 74.78%, #CFE7FF 100%);

}
.discover__top__gap{
    padding-top: 35px;
}
